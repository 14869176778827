.article-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
}

@media all and (max-width: 1200px) {
  .article-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media all and (max-width: 900px) {
  .article-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media all and (max-width: 600px) {
  .article-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
