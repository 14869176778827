.about {
  color: var(--body-colour);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
  max-width: 55rem;
}

.about h1 {
  font-size: 2.75rem;
  font-weight: 700;
  padding-bottom: 0.5rem;
}

.about p {
  font-size: 1.25rem;
  line-height: 180%;
  font-weight: 300;
}

.about .highlight {
  color: var(--primary-title-colour);
  font-weight: 700;
}

.about img {
  max-height: 20rem;
  border-radius: 20px;
}

.about a {
  font-size: 1.25rem;
  color: var(--card-border-color);
  line-height: 180%;
  display: inline-block;
  font-weight: 300;
}

.about a:hover,
a:focus {
  color: var(--primary-title-colour);
}

@media all and (max-width: 600px) {
  .about h1 {
    font-size: 2.5rem;
  }
}
