.card {
  display: flex;
  flex-direction: column;

  /* max-width: 20rem; */

  border-style: solid;
  border-width: 3.5px;
  border-color: var(--card-border-colour);
  border-radius: 20px;

  background-color: var(--card-background-colour);
  overflow: hidden;

  will-change: transform;
  transition: transform 250ms;
}

.card:hover {
  animation-name: bounce;
  animation-timing-function: ease;
  transform: scale(1.05);
}

.text-container {
  margin: 1rem;
}

.card h4 {
  color: var(--body-colour);
  font-size: 1.25rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.card p {
  font-size: 1rem;
  font-weight: 300;
  color: #dcd3d2;
}

.card i {
  font-size: 1rem;
  font-weight: 300;
  color: #dcd3d2;
}
