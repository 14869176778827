.footer {
    color: var(--body-colour);
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 55rem;
  }
  
  /* .footer p {
    font-size: 1.25rem;
    line-height: 180%;
    font-weight: 300;
  } */

  .footer p {
    font-size: 1rem;
    font-weight: 300;
    color: #dcd3d2;
  }