.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0 2rem 2rem;
}

.root-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 70rem;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--card-background-colour);
}

::-webkit-scrollbar-thumb {
  background: var(--card-border-colour);
  border-radius: 20px;
}

* {
  margin: 0;
  padding: 0;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none;
  }
}
