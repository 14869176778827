.article {
  color: var(--body-colour);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 0;
  max-width: 55rem;
  /* white-space: nowrap; */
}

.article .indented {
  padding: 1rem;
}

.article h1 {
  font-size: 2.75rem;
  font-weight: 700;
  padding-bottom: 0.5rem;
}

.article h3 {
  font-size: 1.75rem;
  line-height: 180%;
  font-weight: 600;
}

.article h4 {
  font-size: 1.25rem;
  line-height: 180%;
  font-weight: 600;
}

.article p {
  font-size: 1.25rem;
  line-height: 180%;
  font-weight: 300;
}

.article i {
  font-size: 1.25rem;
  line-height: 180%;
  display: inline-block;
  font-weight: 300;
}

.article a {
  font-size: 1.25rem;
  color: var(--card-border-color);
  line-height: 180%;
  display: inline-block;
  font-weight: 300;
}

.article a:hover,
a:focus {
  color: var(--primary-title-colour);
}

.article hr {
  border: none;
  height: 2px;
  background: #363b55;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.article img {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
}

.article .recommendation-image {
  max-width: 45%;
  border-radius: 20px;
  margin-right: 2.5%;
}

@media all and (max-width: 600px) {
  .article h1 {
    font-size: 2.5rem;
  }

  .article .recommendation-image {
    max-width: 100%;
    border-radius: 20px;
  }
}
