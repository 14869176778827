.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0 0.5rem 0;
  color: var(--body-colour);
  font-size: larger;
  align-self: stretch;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 2rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
}

.nav li:hover {
  color: var(--primary-title-colour);
}

.nav .icon-container {
  will-change: transform;
  transition: transform 250ms;
}

.nav .icon-container:hover {
  animation-name: bounce;
  animation-timing-function: ease;
  transform: translateY(-5px);
}
