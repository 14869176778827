/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

:root {
  /* Colours */
  --primary-background-colour: #0a1128;
  --header-background-colour: #0a1128;
  --card-background-colour: #161d33;
  --card-border-colour: #363b55;

  --primary-title-colour: #fca311;
  --secondary-title-colour: #fefcfb;
  --subtitle-colour: #fefcfb;
  --body-colour: #fefcfb;

  /* Fonts */
  --standard-font: "Nunito";

  /* Font sizes */
  --standard-font-size: 1rem;
  --primary-title-font-size: 4rem;
  --secondary-title-font-size: 2rem;
  --subtitle-font-size: 1.5rem;

  /* Sizes */
  --extra-small-spacing: 4px;
  --small-spacing: 8px;
  --medium-spacing: 16px;
  --large-spacing: 32px;
}

body {
  font-family: var(--standard-font);
}
