.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}

.home hr {
  border: none;
  height: 3px;
  width: 100%;
  background: #363b55;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
