.home-header {
  text-align: center;
  background-color: var(--header-background-colour);
  padding: 1rem 2rem 3rem 2rem;
}

.home-header h1 {
  font-size: var(--primary-title-font-size);
  font-weight: 900;
  color: var(--primary-title-colour);
  padding: 0.25rem 0rem;
}

.home-header h2 {
  font-size: var(--secondary-title-font-size);
  color: var(--secondary-title-colour);
}

.home-header p {
  font-size: var(--subtitle-font-size);
  color: var(--subtitle-colour);
}

@media all and (max-width: 600px) {
  .home-header h1 {
    font-size: 2.5rem;
  }
  .home-header h2 {
    font-size: 1.5rem;
  }
  .home-header p {
    font-size: 1.25rem;
  }
}
