.experience-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 4rem;
}

.experience-container img {
  max-height: 20rem;
  width: auto;
  object-fit: contain;
  border-radius: 20px;
}

.experience {
  background-color: var(--card-background-colour);
  border-radius: 15px;
  height: fit-content;
  max-width: 48rem;
  padding: 2rem;
}

.experience h3 {
  color: var(--body-colour);
  font-size: 1.75rem;
  font-weight: 400;
}

.experience p {
  color: var(--body-colour);
  font-size: 1.15rem;
}

.experience .highlight {
  color: var(--primary-title-colour);
  font-weight: 700;
}

@media all and (max-width: 600px) {
  .experience h3 {
    font-size: 1.5rem;
  }
}